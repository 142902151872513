.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen loader */
  position: relative;
  background-color: white; /* Background color during loading */
}

.carImage {
  width: 150px; /* Adjust size as needed */
  animation: moveCar 2s linear infinite;
}

.road {
  width: 100%;
  /* max-width: 300px; Control the road width */
  height: 6px; /* Road thickness */
  background-color: black;
  margin-top: 10px; /* Space between car and road */
}

@keyframes moveCar {
  0% {
    transform: translateX(-200px); /* Start off the screen */
  }
  50% {
    transform: translateX(200px); /* Move car across the screen */
  }
  100% {
    transform: translateX(-200px); /* Loop back to the start */
  }
}


